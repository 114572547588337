<template>
    <div class="portal_forms">
        <div class="result_wpr new">
            <div class="actions mt-2">
                <ul class="left">
                    <li class="filter_btn">
                        <div class="btn_item pointer" :class="tab === 'stats' ? 'active' : ''" @click="toggleTab('stats')">Your Stats</div>
                        <div class="btn_item pointer" :class="tab === 'ranks' ? 'active' : ''" @click="toggleTab('ranks')">Your Leaderboards</div>
                        <!-- <div class="btn_item pointer" :class="tab === 'habit' ? 'active' : ''" @click="toggleTab('habit')">Habits</div>
                        <div class="btn_item pointer" :class="tab === 'goals' ? 'active' : ''" @click="toggleTab('goals')">Goals</div>
                        <div class="btn_item pointer" :class="tab === 'uploads' ? 'active' : ''" @click="toggleTab('uploads')">Uploads</div> -->
                    </li>
                </ul>
                <ul class="right">
                    <li class="optionDrops contacts-tabs ml-auto" @click.self="timeline = !timeline"  v-click-outside="closeTimelineDropdown" v-if="tab === 'workouts'">
                        {{ selectedtimeline }} <i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="timeline ? 'active' : ''">
                            <ul>
                                <li v-for="(item, t) of timeline_list" :key="t" @click="selectedtimeline = item">{{ item }}</li>
                            </ul>
                        </div>
                    </li>
                    <!-- <li class="fitbit" v-if="tab === 'workouts'">
                        <div class="fitbit_connector">
                            <label for="fitbit" class="switch_option capsule_btn p-0">
                                <h5>Fitbit Connected</h5>
                                <input type="checkbox" id="fitbit" :true-value="1" :false-value="0" v-model="has_fitbit" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                    </li> -->
                    <li class="optionDrops search_area active" v-else>
                        <input type="text" placeholder="Search..."/>
                        <button class="search_btn" @click="searchField = !searchField;">
                            <i class="fas fa-search"></i>
                        </button>
                    </li>
                    <!-- <li class="p-0" v-if="tab === 'ranks'">
                        <label for="dashboard-cover" class="switch_option capsule_btn border-0 p-0">
                            <h5 class="large">{{show_compatitors ? 'Show' : 'Hide'}} Compatitors</h5>
                            <input type="checkbox" id="dashboard-cover" :true-value="1" :false-value="0" v-model="show_compatitors" hidden>
                            <div><span></span></div>
                        </label>
                    </li> -->
                </ul>
            </div>
        </div>
        
        <div class="overview" v-if="tab === 'stats'">
            <!-- <div class="category_title"><h2>Your Stats</h2></div> -->
            <div class="quick_status">
                <div class="quick_card">
                    <div class="title">
                        <svg width="65" height="70" viewBox="0 0 65 70" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="2.5" y1="67.5" x2="62.4999" y2="67.5" stroke="#2f7eed" stroke-width="5" stroke-linecap="round"/><path d="M0.44824 50.9655C-0.299255 52.0854 -0.0898283 53.6683 0.915968 54.5012C1.92176 55.3336 3.34306 55.1001 4.09051 53.9802L0.44824 50.9655ZM58.8938 1.78452C58.5253 0.450629 57.2556 -0.298017 56.058 0.112416L36.5402 6.8008C35.3421 7.21123 34.6701 8.62528 35.0385 9.95918C35.407 11.2931 36.6767 12.0417 37.8743 11.6313L55.2239 5.68604L60.5619 25.008C60.9304 26.3419 62.2001 27.0905 63.3977 26.6801C64.5957 26.2697 65.2678 24.8556 64.8993 23.5217L58.8938 1.78452ZM4.09051 53.9802L18.8879 31.8048L15.2458 28.7901L0.44824 50.9655L4.09051 53.9802ZM22.4166 31.6482L30.2555 41.6247L33.6703 38.2967L25.8319 28.3201L22.4166 31.6482ZM41.394 40.1798L58.7304 3.70999L54.7197 1.34533L37.3833 37.815L41.394 40.1798ZM30.2555 41.6247C33.3972 45.6239 39.1617 44.8759 41.394 40.1798L37.3833 37.815C36.6395 39.3807 34.7177 39.6299 33.6703 38.2967L30.2555 41.6247ZM18.8879 31.8048C19.7506 30.512 21.4646 30.4357 22.4166 31.6482L25.8319 28.3201C22.9752 24.6835 17.8337 24.9119 15.2458 28.7901L18.8879 31.8048Z" fill="#2f7eed"/></svg>
                        Body Composition
                        <ul class="w-100">
                            <li>
                                <select v-model="goalsFilter.duration">
                                    <option :value="duration" v-for="(duration, d) of goalsDurations" :key="d">{{ duration }}</option>
                                </select>
                                <span><i class="fas fa-angle-down"></i></span>
                            </li>
                        </ul>
                    </div>
                    <div class="card_info">
                        <ul>
                            <li>
                                <div class="item_details">
                                    <label>Weight</label>
                                    195 lbs
                                </div>
                                <div class="item_details">
                                    <label>Goal</label>
                                    180 lbs
                                </div>
                                <div class="rate">
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;" title="completion Score">
                                        <div class="inner_circle">
                                            <div class="score">0<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="115" />
                                        </svg>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item_details">
                                    <label>Body Fat</label>
                                    23%
                                </div>
                                <div class="item_details">
                                    <label>Goal</label>
                                    20%
                                </div>
                                <div class="rate">
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;" title="completion Score">
                                        <div class="inner_circle">
                                            <div class="score">0<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="115" />
                                        </svg>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="item_details">
                                    <label>Waist Size</label>
                                    112 cm
                                </div>
                                <div class="item_details">
                                    <label>Goal</label>
                                    102 cm
                                </div>
                                <div class="rate">
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;" title="completion Score">
                                        <div class="inner_circle">
                                            <div class="score">0<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="115" />
                                        </svg>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="actions">
                            <a @click="goals_stats = true;">6 more goals <i class="fas fa-long-arrow-alt-right"></i></a>
                            <button type="button">Change Goals</button>
                        </div>
                    </div>
                </div>
                <div class="quick_card">
                    <div class="title">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#2f7eed" d="M394.633,220.663,269.475,25.174a16,16,0,0,0-26.95,0L117.364,220.665A170.531,170.531,0,0,0,84.1,322.3c0,94.785,77.113,171.9,171.9,171.9s171.9-77.113,171.9-171.9A170.519,170.519,0,0,0,394.633,220.663ZM256,462.2c-77.14,0-139.9-62.758-139.9-139.9a138.758,138.758,0,0,1,27.321-83.058q.319-.432.608-.884L256,63.475,367.967,238.359q.288.453.608.884A138.754,138.754,0,0,1,395.9,322.3C395.9,399.441,333.14,462.2,256,462.2Z"/></svg>
                        Habit Tracking
                        <ul class="w-100">
                            <li>
                                <select v-model="habitsFilter.duration">
                                    <option :value="duration" v-for="(duration, d) of habitsDurations" :key="d">{{ duration }}</option>
                                </select>
                                <span><i class="fas fa-angle-down"></i></span>
                            </li>
                        </ul>
                    </div>
                    <div class="card_info">
                        <div class="plan_name">
                            Hydration
                            <div class="rate">
                                <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;" title="completion Score">
                                    <div class="inner_circle">
                                        <div class="score">62<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="45" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="plan_name">
                            Strength
                            <div class="rate">
                                <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;" title="completion Score">
                                    <div class="inner_circle">
                                        <div class="score">86<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="20" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="plan_name">
                            Vitamins
                            <div class="rate">
                                <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;" title="completion Score">
                                    <div class="inner_circle">
                                        <div class="score">48<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="57" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="actions">
                            <a @click="habits_stats = true;">Details <i class="fas fa-long-arrow-alt-right"></i></a>
                        </div>
                    </div>
                </div>
                <div class="quick_card">
                    <div class="title">
                        <svg viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#2f7eed" stroke-width="5"/><path d="M17.8241 12.762C12.3284 15.751 11.2758 21.319 11.4365 23.7295C11.7981 27.9477 15.5342 31.0813 16.9805 32.166C18.1375 33.0337 19.7123 32.5275 20.3551 32.166C26.8632 28.6708 31.5636 27.4656 41.0848 27.3451C50.6059 27.2246 57.9578 31.6839 59.5245 32.407C60.778 32.9855 61.7341 32.648 62.0555 32.407C66.4907 29.4181 67.8405 25.3766 67.961 23.7295C68.5636 15.6545 60.7297 12.4005 60.0066 12.1594C59.4281 11.9666 57.8372 11.838 57.1141 11.7979C47.7134 11.1952 22.886 11.7979 21.0782 11.7979C19.632 11.7979 18.3062 12.4406 17.8241 12.762Z" stroke="#2f7eed" stroke-width="5"/></svg>
                        Progress Tracking
                        <ul class="w-100">
                            <li>
                                <select v-model="progressFilter.tracking">
                                    <option v-for="(tracker, t) of ProgressScenarioList" :key="t" :value="tracker.title">{{ tracker.title }}</option>
                                </select>
                                <span><i class="fas fa-angle-down"></i></span>
                            </li>
                        </ul>
                    </div>
                    <div class="card_info">
                        <div class="plan_name">
                            {{progressFilter.tracking}}
                            <div class="rate">
                                <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;" title="completion Score">
                                    <div class="inner_circle">
                                        <div class="score">45<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="75" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <ul>
                            <li v-for="(track_item, t) in selectedProgress" :key="t">{{track_item.group_title}} <span class="tag">{{track_item.items.length}} Tracked</span></li>
                        </ul>
                        <div class="actions">
                            <a @click="progress_stats = true;">Details <i class="fas fa-long-arrow-alt-right"></i></a>
                        </div>
                    </div>
                </div>
                <div class="quick_card">
                    <div class="title">
                        <svg viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" stroke="#2f7eed" stroke-width="5"/><path d="M17.8241 12.762C12.3284 15.751 11.2758 21.319 11.4365 23.7295C11.7981 27.9477 15.5342 31.0813 16.9805 32.166C18.1375 33.0337 19.7123 32.5275 20.3551 32.166C26.8632 28.6708 31.5636 27.4656 41.0848 27.3451C50.6059 27.2246 57.9578 31.6839 59.5245 32.407C60.778 32.9855 61.7341 32.648 62.0555 32.407C66.4907 29.4181 67.8405 25.3766 67.961 23.7295C68.5636 15.6545 60.7297 12.4005 60.0066 12.1594C59.4281 11.9666 57.8372 11.838 57.1141 11.7979C47.7134 11.1952 22.886 11.7979 21.0782 11.7979C19.632 11.7979 18.3062 12.4406 17.8241 12.762Z" stroke="#2f7eed" stroke-width="5"/></svg>
                        Workout Tracking
                        <ul class="w-100">
                            <li>
                                <select v-model="plansFilter.duration">
                                    <option :value="duration" v-for="(duration, d) of plansDurations" :key="d">{{ duration }}</option>
                                </select>
                                <span><i class="fas fa-angle-down"></i></span>
                            </li>
                        </ul>
                    </div>
                    <div class="card_info">
                        <div class="plan_name">
                            28 Day Makeover
                            <div class="rate">
                                <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;" title="completion Score">
                                    <div class="inner_circle">
                                        <div class="score">36<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2f7eed" :stroke-dasharray="115" :stroke-dashoffset="75" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <ul>
                            <li>26 Workouts <span class="tag">6 Missed</span></li>
                            <li>38 Blocks <span class="tag">12 Completed</span></li>
                            <li>4 Weeks</li>
                        </ul>
                        <div class="actions">
                            <a @click="workouts_stats = true;">Details <i class="fas fa-long-arrow-alt-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overview" v-if="tab === 'ranks'">
            <!-- <div class="category_title">
                <h2>Your Ranks</h2>
                <label for="dashboard-cover" class="switch_option capsule_btn border-0">
                    <h5 class="large">{{show_compatitors ? 'Show' : 'Hide'}} Compatitors</h5>
                    <input type="checkbox" id="dashboard-cover" :true-value="1" :false-value="0" v-model="show_compatitors" hidden>
                    <div><span></span></div>
                </label>
            </div> -->
            <div class="quick_status">
                <div class="quick_card">
                    <div class="title">
                        <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                            <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" fill="#2F7EED"/>
                            <path d="M67 91L67 109" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                            <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" fill="#2F7EED"/>
                            <path d="M33.8857 3H104.868" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                        </svg>Body Composition
                        <ul class="w-100">
                            <li>
                                <select v-model="rankGoalsFilter.duration">
                                    <option :value="duration" v-for="(duration, d) of rankGoalsDurations" :key="d">{{ duration }}</option>
                                </select>
                                <span><i class="fas fa-angle-down"></i></span>
                            </li>
                        </ul>
                    </div>
                    <ul class="rankboard">
                        <li v-for="(participant, p) in goalsLeaderboard" :key="p">
                            <span class="board_pos">{{p + 1}}</span>
                            <img :src="require(`@/assets/images/${participant.img}`)" :alt="participant.name" v-if="show_compatitors || participant.type == 'me'">
                            <img src="@/assets/images/private_avatar.png" alt="private" v-else>
                            <h5 v-if="show_compatitors || participant.type == 'me'">{{ participant.name }}</h5>
                            <span v-else class="private"></span>
                            <div class="score_circle">
                                <div class="inner_circle">
                                    <div class="score">{{ participant.score }}<span>%</span></div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="90" :stroke-dashoffset="90 - ( participant.score * 0.9 )"/>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="quick_card">
                    <div class="title">
                        <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                            <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" fill="#2F7EED"/>
                            <path d="M67 91L67 109" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                            <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" fill="#2F7EED"/>
                            <path d="M33.8857 3H104.868" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                        </svg>Habit Tracking
                        <ul  class="w-100">
                            <li>
                                <select v-model="rankHabitsFilter.duration">
                                    <option :value="duration" v-for="(duration, d) of rankHabitsDurations" :key="d">{{ duration }}</option>
                                </select>
                                <span><i class="fas fa-angle-down"></i></span>
                            </li>
                        </ul>
                    </div>
                    <ul class="rankboard" :class="{'blurry' : !show_compatitors}">
                        <li v-for="(participant, p) in habitsLeaderboard" :key="p" :class="{'focus' : participant.type == 'me'}">
                            <span class="board_pos">{{p + 1}}</span>
                            <img :src="require(`@/assets/images/${participant.img}`)" :alt="participant.name" v-if="show_compatitors || participant.type == 'me'">
                            <img src="@/assets/images/private_avatar.png" alt="private" v-else>
                            <h5 v-if="show_compatitors || participant.type == 'me'">{{ participant.name }}</h5>
                            <span v-else class="private"></span>
                            <div class="score_circle">
                                <div class="inner_circle">
                                    <div class="score">{{ participant.score }}<span>%</span></div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="90" :stroke-dashoffset="90 - ( participant.score * 0.9 )"/>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="quick_card">
                    <div class="title">
                        <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                            <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" fill="#2F7EED"/>
                            <path d="M67 91L67 109" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                            <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" fill="#2F7EED"/>
                            <path d="M33.8857 3H104.868" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                        </svg>Progress Tracking
                        <ul class="w-100">
                            <li>
                                <select v-model="progressFilter.tracking">
                                    <option v-for="(tracker, t) of ProgressScenarioList" :key="t" :value="tracker.title">{{ tracker.title }}</option>
                                </select>
                                <span><i class="fas fa-angle-down"></i></span>
                            </li>
                        </ul>
                    </div>
                    <ul class="rankboard" :class="{'blurry' : !show_compatitors}">
                        <li v-for="(participant, p) in ProgressLeaderboard" :key="p" :class="{'focus' : participant.type == 'me'}">
                            <span class="board_pos">{{p + 1}}</span>
                            <img :src="require(`@/assets/images/${participant.img}`)" :alt="participant.name" v-if="show_compatitors || participant.type == 'me'">
                            <img src="@/assets/images/private_avatar.png" alt="private" v-else>
                            <h5 v-if="show_compatitors || participant.type == 'me'">{{ participant.name }}</h5>
                            <span v-else class="private"></span>
                            <div class="score_circle">
                                <div class="inner_circle">
                                    <div class="score">{{ participant.score }}<span>%</span></div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="90" :stroke-dashoffset="90 - ( participant.score * 0.9 )"/>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="quick_card">
                    <div class="title">
                        <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                            <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" fill="#2F7EED"/>
                            <path d="M67 91L67 109" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                            <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" fill="#2F7EED"/>
                            <path d="M33.8857 3H104.868" stroke="#2F7EED" stroke-width="5" stroke-linecap="round"/>
                        </svg>Workout Tracking
                        <ul  class="w-100">
                            <li>
                                <select v-model="rankPlansFilter.duration">
                                    <option :value="duration" v-for="(duration, d) of rankPlansDurations" :key="d">{{ duration }}</option>
                                </select>
                                <span><i class="fas fa-angle-down"></i></span>
                            </li>
                        </ul>
                    </div>
                    <ul class="rankboard" :class="{'blurry' : !show_compatitors}">
                        <li v-for="(participant, p) in workoutsLeaderboard" :key="p" :class="{'focus' : participant.type == 'me'}">
                            <span class="board_pos">{{p + 1}}</span>
                            <img :src="require(`@/assets/images/${participant.img}`)" :alt="participant.name" v-if="show_compatitors || participant.type == 'me'">
                            <img src="@/assets/images/private_avatar.png" alt="private" v-else>
                            <h5 v-if="show_compatitors || participant.type == 'me'">{{ participant.name }}</h5>
                            <span v-else class="private"></span>
                            <div class="score_circle">
                                <div class="inner_circle">
                                    <div class="score">{{ participant.score }}<span>%</span></div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                    <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2f7eed" :stroke-dasharray="90" :stroke-dashoffset="90 - ( participant.score * 0.9 )"/>
                                </svg>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="overview" v-if="tab === 'progress'">
            <template v-for="(progress, p) of ProgressScenarioList[selectScenario].categories" :key="p">
                <div v-for="(item, i) of progress.items" :key="i">
                    <track-chart :option="item" v-if="item.is_selected"></track-chart>
                </div>
            </template>
        </div> -->
        <div  v-if="tab === 'goals'">
            <div class="report_card">
                <div class="header">
                    <div class="user_info">
                        <img :src="user.profile_pic" alt="">
                        <div>
                            <h4>{{ user.full_name }}</h4>
                            <h3>Started Tracking &nbsp;<span>&#123;&#123;Oct 21, 2022&#125;&#125;</span></h3>
                        </div>
                    </div>
                    <div class="submission">
                        <h4>&#123;&#123;1/10&#125;&#125;</h4>
                        <h3>Submissions</h3>
                    </div>
                </div>
            </div>
            <div class="section_header">
                <h2>Progress</h2>
            </div>
            <ul class="tracking_list">
                <li>
                    <h4>Participation Score: <span>20%</span></h4>
                    <div class="score_circle" style="background: #efefef; width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.8);">20<span>%</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="20"/>
                        </svg>
                    </div>
                </li>
                <li>
                    <h4>Progress Score: <span>10%</span></h4>
                    <div class="score_circle" style="background: #efefef; width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.8);">10<span>%</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="10"/>
                        </svg>
                    </div>
                </li>
            </ul>
            <div class="progress_tbl">
                <div class="category_title">
                    <h2>Weight</h2>
                    <span>
                        <button type="button" class="pointer">
                            New Report
                        </button>
                        <button type="button" class="pointer ml-2">
                            Update Goals
                        </button>
                    </span>
                </div>
                <div class="result_wpr prog_wpr">
                    <table>
                        <thead>
                            <tr>
                                <th>Start</th>
                                <th>Current</th>
                                <th>Goal</th>
                                <th>Percent of Goal</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><label>Weight:</label> &#123;&#123;23 Pounds&#125;&#125;</td>
                                <td><label>Weight:</label> &#123;&#123;23 Pounds&#125;&#125;</td>
                                <td><label>Weight:</label> &#123;&#123;12 Pounds&#125;&#125;</td>
                                <td>
                                    <h6>0%</h6>
                                    <div class="status"><span :style="`width: ${0}%;`"></span></div>
                                </td>
                                <td>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.8);">0<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#2f7eed'" :stroke-dasharray="202" :stroke-dashoffset="202"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="category_title">
                    <h2>Body Fat</h2>
                </div>
                <div class="result_wpr prog_wpr">
                    <table>
                        <thead>
                            <tr>
                                <th>Start</th>
                                <th>Current</th>
                                <th>Goal</th>
                                <th>Percent of Goal</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><label>Body Fat:</label> &#123;&#123;23 %&#125;&#125;</td>
                                <td><label>Body Fat:</label> &#123;&#123;23 %&#125;&#125;</td>
                                <td><label>Body Fat:</label> &#123;&#123;32 %&#125;&#125;</td>
                                <td>
                                    <h6>0%</h6>
                                    <div class="status"><span :style="`width: ${0}%;`"></span></div>
                                </td>
                                <td>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.8);">0<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#2f7eed'" :stroke-dasharray="202" :stroke-dashoffset="202"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="category_title">
                    <h2>Circumference</h2>
                </div>
                <div class="result_wpr prog_wpr">
                    <table>
                        <thead>
                            <tr>
                                <th>Start</th>
                                <th>Current</th>
                                <th>Goal</th>
                                <th>Percent of Goal</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><label class="capitalize">Neck:</label> 34 Inches</td>
                                <td><label class="capitalize">Neck:</label> 34 Inches</td>
                                <td><label class="capitalize">Neck:</label> 19 Inches</td>
                                <td>
                                    <h6>0%</h6>
                                    <div class="status"><span :style="`width: ${0}%;`"></span></div>
                                </td>
                                <td>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.8);">0<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#2f7eed'" :stroke-dasharray="202" :stroke-dashoffset="202"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><label class="capitalize">Shoulders:</label> 23 Inches</td>
                                <td><label class="capitalize">Shoulders:</label> 23 Inches</td>
                                <td><label class="capitalize">Shoulders:</label> 21 Inches</td>
                                <td>
                                    <h6>0%</h6>
                                    <div class="status"><span :style="`width: ${0}%;`"></span></div>
                                </td>
                                <td>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.8);">0<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#2f7eed'" :stroke-dasharray="202" :stroke-dashoffset="202"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><label class="capitalize">Biceps:</label> 34 Inches</td>
                                <td><label class="capitalize">Biceps:</label> 34 Inches</td>
                                <td><label class="capitalize">Biceps:</label> 11 Inches</td>
                                <td>
                                    <h6>0%</h6>
                                    <div class="status"><span :style="`width: ${0}%;`"></span></div>
                                </td>
                                <td>
                                    <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.8);">0<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#2f7eed'" :stroke-dasharray="202" :stroke-dashoffset="202"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="tab === 'habit'">
            <div class="report_card">
                <div class="header">
                    <div class="user_info">
                        <img :src="user.profile_pic" alt="">
                        <div>
                            <h4>{{ user.full_name }}</h4>
                            <h3>Started Tracking &nbsp;<span>&#123;&#123;Oct 21, 2022&#125;&#125;</span></h3>
                        </div>
                    </div>
                    <div class="submission">
                        <h4>&#123;&#123;1/115&#125;&#125;</h4>
                        <h3>Submissions</h3>
                    </div>
                </div>
            </div>
            <div class="section_header">
                <h2>Habits</h2>
                <div>
                    <button type="button" class="pointer" @click="calendarView = ! calendarView">{{ calendarView ? 'List View' : 'Calendar View' }}</button>
                </div>
            </div>
            <ul class="tracking_list">
                <li>
                    <h4>Participation Score: <span>20%</span></h4>
                    <div class="score_circle" style="background: #efefef; width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.8);">20<span>%</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="20"/>
                        </svg>
                    </div>
                </li>
                <li>
                    <h4>Habit Score: <span>10%</span></h4>
                    <div class="score_circle" style="background: #efefef; width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.8);">10<span>%</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="10"/>
                        </svg>
                    </div>
                </li>
                <li>
                    <h4>Best Streak: <span>0 <small>Day</small></span></h4>
                    <div class="score_circle" style="background: #efefef; width:40px; height:40px; padding: 3px;">
                        <div class="inner_circle">
                            <div class="score" style="transform: scale(0.8);">0 <span>D</span></div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="0"/>
                        </svg>
                    </div>
                </li>
            </ul>
            <div class="habit_calendar" v-show="calendarView">
                <v-calendar :first-day-of-week="2" :attributes="attributes" is-expanded>
                    <template #day-popover="{ dayTitle }">
                        <div class="task_details">
                            <div class="task_header">
                                {{ dayTitle }}
                            </div>
                            <div class="quest_panel">
                                <template v-for="(category, index) in categories" :key="index">
                                    <h3 class="category_title">{{ category.name }}</h3>
                                    <div class="category_wpr">
                                        <div class="task_info" v-for="(item, i) of category.habits" :key="i">
                                            <h4><label>{{ i + 1 }}</label>{{ item.description }}</h4>
                                            <div class="details">
                                                <p>{{ item.requirements ? item.requirements : 'No requirement available'}}</p>
                                                <div class="perform_activity" v-if="item.type == 1">
                                                    <div class="activity_wpr_center">
                                                        <span>Completed?</span>
                                                        <div class="grp_wpr">
                                                            <label class="yes">
                                                                <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                                            </label>
                                                            <label class="no">
                                                                <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="perform_activity" v-if="item.type == 2">
                                                    <div class="success_goal"><i class="fas fa-trophy"></i>{{ `Goal is ${ item.success_goal > 1 ? item.success_goal+' days' : item.success_goal+' day' }` }} per week</div>
                                                    <div class="days_wpr">
                                                        <span>Days this week I was compliant!</span>
                                                        <ul class="days">
                                                            <li v-for="(week, index) in weekdays" :key="index">
                                                                <label class="active">
                                                                    <div class="item_wpr">
                                                                        <span class="weekdays">{{ week.title.slice(0, 3) }}</span>
                                                                        <span class="tick_box active"></span>
                                                                    </div>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="upload_image" v-show="item.submit_proof">
                                                    <image-upload image-type="all" upload-text="Image"  :is-avatar="false" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                </v-calendar>
                <div class="streaks">
                    <h4>Streaks</h4>
                    <ul>
                        <li><i class="fas fa-trophy"></i></li>
                        <li><i class="fas fa-trophy"></i></li>
                        <li><i class="fas fa-trophy"></i></li>
                        <li><i class="fas fa-trophy"></i></li>
                        <li><i class="fas fa-trophy"></i></li>
                    </ul>
                </div>
            </div>
            <ul class="habit_list px-0" v-show="!calendarView">
                <li v-for="(category, index) of categories" :key="index">
                    <div class="category_title"><h2>{{ category.name }}</h2></div>
                    <div class="habit_wpr" v-for="(item, i) of category.habits" :key="i">
                        <div class="title">
                            {{ item.description ? item.description : '&nbsp;' }}
                            <div class="right">
                                <div class="dropdown" @click="requirementToggle = requirementToggle == item.id ? 0 : item.id">
                                    <i class="fas fa-info-circle"></i>
                                    <div class="drp_wrapper" v-if="requirementToggle == item.id" :class="{ active : requirementToggle == item.id }">
                                        <div class="quick_info">
                                            <div class="info_title">Requirement</div>
                                            <p>{{ item.requirements }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="habit_info">
                            <ul>
                                <li>
                                    <div class="score_circle" style="width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.8);">0<span>%</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="100"/>
                                        </svg>
                                    </div>
                                    <h6>Habit Score</h6>
                                </li>
                                <li>
                                    <div class="score_circle" style="width:40px; height:40px; padding: 3px;">
                                        <div class="inner_circle">
                                            <div class="score" style="transform: scale(0.8);">0<span>D</span></div>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                            <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="100"/>
                                        </svg>
                                    </div>
                                    <h6>Best Streak</h6>
                                </li>
                            </ul>
                        </div>
                        <div class="habit_footer">
                            <ul class="day_sorting" v-if="habitScores" @mousewheel="scrollFunc($event)">
                                <template v-for="(answer, week) of habitScores" :key="week">
                                    <li @click="handleWeekDialog(week + 1)">
                                        <i :class="`far fa-square`"></i>Day {{ parseInt(week) + 1 }}
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        
        <!-- <div class="progress_filter_bar" :class="{'show' : openFilter}">
            <h4>
                Filter
                <button type="button" class="close_btn" @click="closeFilterBar()"><i class="fas fa-times"></i></button>
            </h4>
            <div class="scenario_box" @click="scenarioDropdown = !scenarioDropdown" v-click-outside="closeScenarioDropdown">
                {{ProgressScenarioList[selectScenario].title}}<i class="fas fa-angle-down"></i>
                <div class="dropdown_wpr" :class="scenarioDropdown ? 'active' : ''">
                    <ul>
                        <li v-for="(scenario, s) in ProgressScenarioList" :key="s" @click="selectScenario = s;">{{scenario.title}}</li>
                    </ul>
                </div>
            </div>
            <div class="category_wpr">
                <div class="category_grp active" v-for="(progress, p) of ProgressScenarioList[selectScenario].categories" :key="p">
                    <h4>{{progress.group_title}}</h4>
                    <ul>
                        <li v-for="(item, i) of progress.items" :key="i" @click="item.is_selected = !item.is_selected">
                            <label :for="`filter-${i}`" class="checkbox">
                                <span :class="{'active' : item.is_selected}"><i class="fas fa-check"></i></span>
                                <h5>{{ item.name }}</h5>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
        
        <div class="empty_section mb-0" v-if="tab === 'uploads'">
            <h1 class="text-center">You Need To Set Your Goal First</h1>
            <button type="button" class="pointer custom_element">Proceed</button>
        </div>
        <div class="modal secondary details_dialog" v-if="WeekDialog">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1 class="m-0">Day {{ weekSr }}</h1>
                    <button class="close_btn" @click="WeekDialog = false;"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal_body">
                    <div class="task_details">
                        <template v-for="(category, index) in categories" :key="index">
                            <h3 class="category_title">{{ category.name }}</h3>
                            <div class="category_wpr">
                                <div class="task_info" v-for="(item, i) of category.habits" :key="i">
                                    <h4><label>{{ i + 1 }}</label>{{ item.description }}</h4>
                                    <div class="details">
                                        <p>{{ item.requirements ? item.requirements : 'No requirement available'}}</p>
                                        <div class="perform_activity" v-if="item.type == 1">
                                            <div class="activity_wpr_center">
                                                <span>Completed?</span>
                                                <div class="grp_wpr">
                                                    <label class="yes">
                                                        <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                                    </label>
                                                    <label class="no">
                                                        <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="perform_activity" v-if="item.type == 2">
                                            <div class="success_goal"><i class="fas fa-trophy"></i>{{ `Goal is ${ item.success_goal > 1 ? item.success_goal+' days' : item.success_goal+' day' }` }} per week</div>
                                            <div class="days_wpr">
                                                <span>Days this week I was compliant!</span>
                                                <ul class="days">
                                                    <li v-for="(week, index) in weekdays" :key="index">
                                                        <label class="active">
                                                            <div class="item_wpr">
                                                                <span class="weekdays">{{ week.title.slice(0, 3) }}</span>
                                                                <span class="tick_box active"></span>
                                                            </div>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="upload_image" v-show="item.submit_proof">
                                            <image-upload image-type="all" upload-text="Image"  :is-avatar="false" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <workouts-stats-details v-model="workouts_stats"/>
        <habits-stats-details v-model="habits_stats"/>
        <progress-stats-details v-model="progress_stats"/>
        <goals-stats-details v-model="goals_stats"/>
    </div>
</template>
<script>
    import { defineAsyncComponent } from 'vue'
    import { VueEcharts } from 'vue3-echarts'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))
    const WorkoutsStatsDetails = defineAsyncComponent(() => import('../components/WorkoutsStatsDetails'))
    const HabitsStatsDetails = defineAsyncComponent(() => import('../components/HabitsStatsDetails'))
    const ProgressStatsDetails = defineAsyncComponent(() => import('../components/ProgressStatsDetails'))
    const GoalsStatsDetails = defineAsyncComponent(() => import('../components/GoalsStatsDetails'))

    import { mapState } from 'vuex'

    export default {
        name: 'Client Portal Progress',

        data () {
            return {
                tab: 'stats',
                searchField: false,
                calendarView: true,
                WeekDialog: false,
                weekSr: 0,
                requirementToggle: 0,
                attributes: [
                    {
                        key: 1,
                        dates: new Date(),
                        highlight: {
                            class: false,
                            fillMode: 'solid',
                            contentClass: 'assign_day',
                        },
                        // dot: {
                        //     style: {
                        //         backgroundColor: 'transparent',
                        //     }
                        // },
                        popover: {
                            visibility: 'click',
                        },
                    },
                ],
                categories: [
                    {
                        name:"Sleep",
                        habits:[
                            {
                                id: 1,
                                type: 1,
                                name:"Sleep",
                                description:"Did you sleep at least 7 hours last night?",
                                requirements:"To qualify for the challenge you must sleep at least 7 hours.",
                                success_goal:7,
                                submit_proof:1,
                            },
                            {
                                id: 2,
                                type: 2,
                                name:"Sleep",
                                description:"Did you sleep at least 7 hours last night?",
                                requirements:"To qualify for the challenge you must sleep at least 7 hours.",
                                success_goal:7,
                                submit_proof:1,
                            }
                        ]
                    },
                ],
                weekdays: [
                    { title: 'Monday', label: 'M', value: 1 },
                    { title: 'Tuesday', label: 'T', value: 2 },
                    { title: 'Wednesday', label: 'W', value: 3 },
                    { title: 'Thursday', label: 'T', value: 4 },
                    { title: 'Friday', label: 'F', value: 5 },
                    { title: 'Saturday', label: 'S', value: 6 },
                    { title: 'Sunday', label: 'S', value: 0 },
                ],
                logo: [],
                habitScores: [ "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "" ],
                timeline: false,
                selectedtimeline: 'Last 30 Days',
                timeline_list:[
                    'Last 30 Days',
                    'Last 15 Days',
                    'Last Week',
                ],
                personal_records: {
                    mode: 'single',
                    value: ['My PR\'s'],
                    options: [
                        { value: 'My PR\'s', label: 'My PR\'s' },
                        { value: 'Bench Press', label: 'Bench Press' },
                        { value: 'Squad', label: 'Squad' },
                        { value: 'Dead Lift', label: 'Dead Lift' },
                        { value: 'Snatch', label: 'Snatch' },
                    ],
                },
                rankGoalsFilter: {
                    status: '',
                    duration: 'Loose Weight & Fat',
                },
                rankGoalsDurations: [
                    'Loose Weight & Fat',
                    'Biceps & Forearms Gain',
                    '28 Day Abs Challenge',
                ],
                goalsFilter: {
                    status: '',
                    duration: 'Loose Weight & Fat',
                },
                goalsDurations: [
                    'Loose Weight & Fat',
                    'Biceps & Forearms Gain',
                    '28 Day Abs Challenge',
                ],
                rankHabitsFilter: {
                    status: '',
                    duration: '6 Week Habits',
                },
                rankHabitsDurations: [
                    '6 Week Habits',
                    '21 Day Habit Challenge',
                ],
                habitsFilter: {
                    status: '',
                    duration: '6 Week Habits',
                },
                habitsDurations: [
                    '6 Week Habits',
                    '21 Day Habit Challenge',
                ],
                rankPlansFilter: {
                    status: '',
                    duration: '28 Day Makeover',
                },
                rankPlansDurations: [
                    '28 Day Makeover',
                    '30-Day Plank Challenge',
                ],
                progressFilter: {
                    tracking: 'Progress Plan',
                },
                plansFilter: {
                    status: '',
                    duration: '28 Day Makeover',
                },
                plansDurations: [
                    '28 Day Makeover',
                    '30-Day Plank Challenge',
                ],
                has_fitbit: 1,
                lb_tabs: 'workouts',
                show_compatitors: 0,
                goalsLeaderboard:[
                    {
                        name: 'Anna Marrie',
                        img: 'male-female.png',
                        score: 45,
                        type: 'unknown',
                    },
                    {
                        name: 'Ryan Jordan',
                        img: 'male-female.png',
                        score: 40,
                        type: 'me',
                    },
                    {
                        name: 'Jason Wayne',
                        img: 'male-female.png',
                        score: 33,
                        type: 'unknown',
                    },
                    {
                        name: 'Kathy Bell',
                        img: 'male-female.png',
                        score: 30,
                        type: 'unknown',
                    },
                    {
                        name: 'Nancy Wallace',
                        img: 'male-female.png',
                        score: 28,
                        type: 'unknown',
                    },
                    {
                        name: 'Roman Tudor',
                        img: 'male-female.png',
                        score: 26,
                        type: 'unknown',
                    },
                    {
                        name: 'Tim Charles',
                        img: 'male-female.png',
                        score: 25,
                        type: 'unknown',
                    },
                    {
                        name: 'Josh Law',
                        img: 'male-female.png',
                        score: 24,
                        type: 'unknown',
                    },
                    {
                        name: 'Lucas Freeman',
                        img: 'male-female.png',
                        score: 23,
                        type: 'unknown',
                    },
                    {
                        name: 'Julie Georges',
                        img: 'male-female.png',
                        score: 22,
                        type: 'unknown',
                    },
                    {
                        name: 'Pierre Loger',
                        img: 'male-female.png',
                        score: 21,
                        type: 'unknown',
                    },
                    {
                        name: 'Justin Himes',
                        img: 'male-female.png',
                        score: 20,
                        type: 'unknown',
                    },
                ],
                habitsLeaderboard:[
                    {
                        name: 'Jason Wayne',
                        img: 'male-female.png',
                        score: 45,
                        type: 'unknown',
                    },
                    {
                        name: 'Anna Marrie',
                        img: 'male-female.png',
                        score: 40,
                        type: 'unknown',
                    },
                    {
                        name: 'Ryan Jordan',
                        img: 'male-female.png',
                        score: 38,
                        type: 'me',
                    },
                    {
                        name: 'Kathy Bell',
                        img: 'male-female.png',
                        score: 30,
                        type: 'unknown',
                    },
                    {
                        name: 'Nancy Wallace',
                        img: 'male-female.png',
                        score: 28,
                        type: 'unknown',
                    },
                    {
                        name: 'Roman Tudor',
                        img: 'male-female.png',
                        score: 26,
                        type: 'unknown',
                    },
                    {
                        name: 'Tim Charles',
                        img: 'male-female.png',
                        score: 25,
                        type: 'unknown',
                    },
                    {
                        name: 'Josh Law',
                        img: 'male-female.png',
                        score: 24,
                        type: 'unknown',
                    },
                    {
                        name: 'Lucas Freeman',
                        img: 'male-female.png',
                        score: 23,
                        type: 'unknown',
                    },
                    {
                        name: 'Julie Georges',
                        img: 'male-female.png',
                        score: 22,
                        type: 'unknown',
                    },
                    {
                        name: 'Pierre Loger',
                        img: 'male-female.png',
                        score: 21,
                        type: 'unknown',
                    },
                    {
                        name: 'Justin Himes',
                        img: 'male-female.png',
                        score: 20,
                        type: 'unknown',
                    },
                ],
                ProgressLeaderboard:[
                    {
                        name: 'Jason Wayne',
                        img: 'male-female.png',
                        score: 45,
                        type: 'unknown',
                    },
                    {
                        name: 'Anna Marrie',
                        img: 'male-female.png',
                        score: 40,
                        type: 'unknown',
                    },
                    {
                        name: 'Kathy Bell',
                        img: 'male-female.png',
                        score: 38,
                        type: 'unknown',
                    },
                    {
                        name: 'Nancy Wallace',
                        img: 'male-female.png',
                        score: 30,
                        type: 'unknown',
                    },
                    {
                        name: 'Ryan Jordan',
                        img: 'male-female.png',
                        score: 28,
                        type: 'me',
                    },
                    {
                        name: 'Roman Tudor',
                        img: 'male-female.png',
                        score: 26,
                        type: 'unknown',
                    },
                    {
                        name: 'Tim Charles',
                        img: 'male-female.png',
                        score: 25,
                        type: 'unknown',
                    },
                    {
                        name: 'Josh Law',
                        img: 'male-female.png',
                        score: 24,
                        type: 'unknown',
                    },
                    {
                        name: 'Lucas Freeman',
                        img: 'male-female.png',
                        score: 23,
                        type: 'unknown',
                    },
                    {
                        name: 'Julie Georges',
                        img: 'male-female.png',
                        score: 22,
                        type: 'unknown',
                    },
                    {
                        name: 'Pierre Loger',
                        img: 'male-female.png',
                        score: 21,
                        type: 'unknown',
                    },
                    {
                        name: 'Justin Himes',
                        img: 'male-female.png',
                        score: 20,
                        type: 'unknown',
                    },
                ],
                workoutsLeaderboard:[
                    {
                        name: 'Anna Marrie',
                        img: 'male-female.png',
                        score: 41,
                        type: 'unknown',
                    },
                    {
                        name: 'Ryan Jordan',
                        img: 'male-female.png',
                        score: 39,
                        type: 'me',
                    },
                    {
                        name: 'Jason Wayne',
                        img: 'male-female.png',
                        score: 35,
                        type: 'unknown',
                    },
                    {
                        name: 'Kathy Bell',
                        img: 'male-female.png',
                        score: 30,
                        type: 'unknown',
                    },
                    {
                        name: 'Nancy Wallace',
                        img: 'male-female.png',
                        score: 28,
                        type: 'unknown',
                    },
                    {
                        name: 'Roman Tudor',
                        img: 'male-female.png',
                        score: 26,
                        type: 'unknown',
                    },
                    {
                        name: 'Tim Charles',
                        img: 'male-female.png',
                        score: 25,
                        type: 'unknown',
                    },
                    {
                        name: 'Josh Law',
                        img: 'male-female.png',
                        score: 24,
                        type: 'unknown',
                    },
                    {
                        name: 'Lucas Freeman',
                        img: 'male-female.png',
                        score: 23,
                        type: 'unknown',
                    },
                    {
                        name: 'Julie Georges',
                        img: 'male-female.png',
                        score: 22,
                        type: 'unknown',
                    },
                    {
                        name: 'Pierre Loger',
                        img: 'male-female.png',
                        score: 21,
                        type: 'unknown',
                    },
                    {
                        name: 'Justin Himes',
                        img: 'male-female.png',
                        score: 20,
                        type: 'unknown',
                    },
                ],
                goals_stats: false,
                habits_stats: false,
                progress_stats: false,
                workouts_stats: false,
                openFilter: false,
                scenarioDropdown: false,
                ProgressScenarioList: [
                    {
                        title: 'Progress Plan',
                        categories:[
                            {
                                group_title: 'Nutrition',
                                items:[
                                    {
                                        name: 'Water',
                                        is_selected: 1,
                                        img: 'water.svg',
                                        title: 'Water',
                                        subtext: 'How much water did you drink?',
                                        unit: {
                                            mode: 'single',
                                            value: 'oz',
                                            options: [
                                                { value: 'oz', label: 'Ounce' },
                                                { value: 'ml', label: 'Milliliters' }
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                // areaStyle: {
                                                //     color: {
                                                //         type: 'linear',
                                                //         x: 0,
                                                //         y: 0,
                                                //         x2: 0,
                                                //         y2: 1,
                                                //         colorStops: [
                                                //             {
                                                //                 offset: 0,
                                                //                 color: 'rgba(47,127,237,0.15)'
                                                //             },
                                                //             {
                                                //                 offset: 1,
                                                //                 color: 'rgba(58,77,233,0)'
                                                //             }
                                                //         ],
                                                //         global: false
                                                //     },
                                                // },
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Calories Consumed',
                                        is_selected: 1,
                                        img: 'calories-consume.svg',
                                        title: 'Calories Consumed',
                                        subtext: 'How many calories have you consumed today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'kcal',
                                            options: [
                                                { value: 'kcal', label: 'kcal' }
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Fruits',
                                        is_selected: 1,
                                        img: 'fruits.svg',
                                        title: 'Fruits',
                                        subtext: 'How much fruits have you consumed today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'pcs',
                                            options: [
                                                { value: 'pcs', label: 'Pieces' },
                                                { value: 'servings', label: 'Servings' },
                                                { value: 'cups', label: 'Cups' },
                                                { value: 'gm', label: 'Grams' },
                                                { value: 'oz', label: 'Ounce' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Vegetables',
                                        is_selected: 1,
                                        img: 'vegetables.svg',
                                        title: 'Vegetables',
                                        subtext: 'How much vegetables have you consumed today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'pcs',
                                            options: [
                                                { value: 'pcs', label: 'Pieces' },
                                                { value: 'servings', label: 'Servings' },
                                                { value: 'cups', label: 'Cups' },
                                                { value: 'gm', label: 'Grams' },
                                                { value: 'oz', label: 'Ounce' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Macros',
                                        is_selected: 1,
                                        img: 'macros.png',
                                        title: 'Macros',
                                        subtext: 'How much fat, protein, and carbs have you consumed today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'gm',
                                            options: [
                                                { value: '%', label: 'Percentage' },
                                                { value: 'gm', label: 'Grams' },
                                                { value: 'fists', label: 'Fists' },
                                                { value: 'palms', label: 'Palms' },
                                                { value: 'thumbs', label: 'Thumbs' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: 'Protein',
                                                type: 'bar',
                                                stack: 'total',
                                                label: {
                                                    show: true,
                                                    color: '#fff',
                                                    opacity: 1,
                                                    formatter: function(params) {
                                                        return `P ${params.value}`;
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                barWidth: 70,
                                                itemStyle: {
                                                    color: '#2f7eed',
                                                    opacity: 1,
                                                },
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 0,
                                                    label: {
                                                        show: false,
                                                        fontSize: 10,
                                                        color: '#fff',
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 0,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 0,
                                                    },
                                                }
                                            },
                                            {
                                                name: 'Fat',
                                                type: 'bar',
                                                stack: 'total',
                                                label: {
                                                    show: true,
                                                    color: '#fff',
                                                    opacity: 1,
                                                    formatter: function(params) {
                                                        return `F ${params.value}`;
                                                    }
                                                },
                                                data: [25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20, 40, 35, 60, 42, 35, 25, 20],
                                                barWidth: 70,
                                                itemStyle: {
                                                    color: '#2f7eed',
                                                    opacity: 0.80,
                                                },
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 0,
                                                    label: {
                                                        show: false,
                                                        fontSize: 10,
                                                        color: '#fff',
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 50},
                                                        {name: 'Min', yAxis: 30},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 0,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 50 },
                                                            { yAxis: 30 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 0,
                                                    },
                                                }
                                            },
                                            {
                                                name: 'Carbs',
                                                type: 'bar',
                                                stack: 'total',
                                                label: {
                                                    show: true,
                                                    color: '#fff',
                                                    opacity: 1,
                                                    formatter: function(params) {
                                                        return `C ${params.value}`;
                                                    }
                                                },
                                                data: [20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15, 35, 30, 55, 37, 30, 20, 15],
                                                barWidth: 70,
                                                itemStyle: {
                                                    color: '#2f7eed',
                                                    opacity: 0.60,
                                                },
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 0,
                                                    label: {
                                                        show: false,
                                                        fontSize: 10,
                                                        color: '#fff',
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 45},
                                                        {name: 'Min', yAxis: 25},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 0,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 45 },
                                                            { yAxis: 25 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 0,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Fat',
                                        is_selected: 1,
                                        img: 'fat.svg',
                                        title: 'Fat',
                                        subtext: 'How much fat have you consumed today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'gm',
                                            options: [
                                                { value: '%', label: 'Percentage' },
                                                { value: 'gm', label: 'Grams' },
                                                { value: 'fists', label: 'Fists' },
                                                { value: 'palms', label: 'Palms' },
                                                { value: 'thumbs', label: 'Thumbs' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    }
                                ]
                            },
                            {
                                group_title: 'Digestion',
                                items:[
                                    {
                                        name: 'Hunger',
                                        is_selected: 1,
                                        img: 'hunger.svg',
                                        title: 'Hunger',
                                        subtext: 'What\'s your level of hunger today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Fullness',
                                        is_selected: 1,
                                        img: 'fullness.svg',
                                        title: 'Fullness',
                                        subtext: 'What\'s your fullness level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Bloat',
                                        is_selected: 1,
                                        img: 'bloat.svg',
                                        has_show: 0,
                                        title: 'Bloat',
                                        subtext: 'What\'s your bloat level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },  
                                ]
                            },
                            {
                                group_title: 'Fitness',
                                items:[
                                    {
                                        name: 'Hunger',
                                        is_selected: 1,
                                        img: 'hunger.svg',
                                        title: 'Hunger',
                                        subtext: 'What\'s your level of hunger today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Fullness',
                                        is_selected: 1,
                                        img: 'fullness.svg',
                                        title: 'Fullness',
                                        subtext: 'What\'s your fullness level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Bloat',
                                        is_selected: 1,
                                        img: 'bloat.svg',
                                        has_show: 0,
                                        title: 'Bloat',
                                        subtext: 'What\'s your bloat level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },  
                                ]
                            },
                        ]
                    },
                    {
                        title: 'Brian Wed Demo Test',
                        categories:[
                            {
                                group_title: 'Exercise',
                                items:[
                                    {
                                        name: 'Readiness',
                                        is_selected: 0,
                                        img: 'readiness.svg',
                                        title: 'Readiness',
                                        subtext: 'What\'s your readiness level?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Step Count',
                                        is_selected: 0,
                                        img: 'steps.svg',
                                        title: 'Step Count',
                                        subtext: 'How many steps did you take today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'steps',
                                            options: [
                                                { value: 'steps', label: 'Steps' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Distance',
                                        is_selected: 0,
                                        img: 'distance.svg',
                                        title: 'Distance',
                                        subtext: 'How much distance did you cover today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'km',
                                            options: [
                                                { value: 'km', label: 'Kilometers' },
                                                { value: 'mi', label: 'Miles' }
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Calories Burned',
                                        is_selected: 0,
                                        img: 'calories.png',
                                        title: 'Calories Burned',
                                        subtext: 'How many calories have you burned today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'kcal',
                                            options: [
                                                { value: 'kcal', label: 'kcal' }
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Zones',
                                        is_selected: 0,
                                        img: 'zone.svg',
                                        title: 'Your Heart Rate Zones',
                                        subtext: 'What\'s your heart rate zones?',
                                        unit: {
                                            mode: 'single',
                                            value: '%',
                                            options: [
                                                { value: '%', label: 'Percentage' },
                                                { value: 'min', label: 'Minutes' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: 'Fat Loss',
                                                type: 'bar',
                                                label: {
                                                    show: true,
                                                    position: 'insideBottom',
                                                    distance: 10,
                                                    opacity: 1,
                                                    formatter: function(params) {
                                                        return `${params.value}`;
                                                    }
                                                },
                                                data: [30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25, 35, 30, 30, 25, 35, 30, 25],
                                                itemStyle: {
                                                    color: '#2f7eed',
                                                    opacity: 1,
                                                },
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    symbolOffset: [0, -15],
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 40},
                                                        {name: 'Min', yAxis: 30},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 40 },
                                                            { yAxis: 30 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            },
                                            {
                                                name: 'Cardio',
                                                type: 'bar',
                                                label: {
                                                    show: true,
                                                    position: 'insideBottom',
                                                    distance: 10,
                                                    opacity: 1,
                                                    formatter: function(params) {
                                                        return `${params.value}`;
                                                    }
                                                },
                                                data: [35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20, 30, 35, 35, 30, 35, 35, 20],
                                                itemStyle: {
                                                    color: '#2f7eed',
                                                    opacity: 0.80,
                                                },
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    symbolOffset: [0, -15],
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 35},
                                                        {name: 'Min', yAxis: 25},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 35 },
                                                            { yAxis: 25 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            },
                                            {
                                                name: 'Peak',
                                                type: 'bar',
                                                label: {
                                                    show: true,
                                                    position: 'insideBottom',
                                                    distance: 10,
                                                    opacity: 1,
                                                    formatter: function(params) {
                                                        return `${params.value}`;
                                                    }
                                                },
                                                data: [25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30, 25, 20, 25, 20, 25, 25, 30],
                                                itemStyle: {
                                                    color: '#2f7eed',
                                                    opacity: 0.60,
                                                },
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    symbolOffset: [0, -15],
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 25},
                                                        {name: 'Min', yAxis: 15},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Resting HR',
                                        is_selected: 0,
                                        img: 'resting-heart-rate.svg',
                                        title: 'Resting Heart Rate',
                                        subtext: 'What\'s your resting heart rate today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'bpm',
                                            options: [
                                                { value: 'bpm', label: 'bpm' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Blood Pressure',
                                        is_selected: 0,
                                        img: 'blood-pressure.svg',
                                        title: 'Blood Pressure',
                                        subtext: 'What\'s your blood pressure today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'mmHg',
                                            options: [
                                                { value: 'mmHg', label: 'mmHg' }
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: 'Systolic',
                                                type: 'bar',
                                                label: {
                                                    show: true,
                                                    position: 'insideBottom',
                                                    distance: 10,
                                                    opacity: 1,
                                                    formatter: function(params) {
                                                        return `${params.value}`;
                                                    }
                                                },
                                                data: [130, 120, 130, 135, 120, 125, 115, 130, 120, 130, 135, 120, 125, 115, 130, 120, 130, 135, 120, 125, 115, 130, 120, 130, 135, 120, 125, 115, 130, 120],
                                                itemStyle: {
                                                    color: '#2f7eed',
                                                    opacity: 1,
                                                },
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    symbolOffset: [0, -15],
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 120},
                                                        {name: 'Min', yAxis: 110},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 120 },
                                                            { yAxis: 110 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            },
                                            {
                                                name: 'Diastolic',
                                                type: 'bar',
                                                label: {
                                                    show: true,
                                                    position: 'insideBottom',
                                                    distance: 10,
                                                    opacity: 1,
                                                    formatter: function(params) {
                                                        return `${params.value}`;
                                                    }
                                                },
                                                data: [85, 80, 85, 90, 80, 85, 75, 85, 80, 85, 90, 80, 85, 75, 85, 80, 85, 90, 80, 85, 75, 85, 80, 85, 90, 80, 85, 75, 85, 80],
                                                itemStyle: {
                                                    color: '#2f7eed',
                                                    opacity: 0.80,
                                                },
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    symbolOffset: [0, -15],
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 80},
                                                        {name: 'Min', yAxis: 70},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 80 },
                                                            { yAxis: 70 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            },
                                        ]
                                    },
                                    {
                                        name: 'HR Variability',
                                        is_selected: 0,
                                        img: 'heart-rate-variability.svg',
                                        title: 'HR Variability',
                                        subtext: 'What\'s your heart rate variability today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'ms',
                                            options: [
                                                { value: 'ms', label: 'Milliseconds' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'O2 Saturation',
                                        is_selected: 0,
                                        img: 'oxygen-saturation.svg',
                                        title: 'O2 Saturation',
                                        subtext: 'What\'s your oxygen saturation today?',
                                        unit: {
                                            mode: 'single',
                                            value: '%',
                                            options: [
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'VO2 Max',
                                        is_selected: 0,
                                        img: 'vo2-max.svg',
                                        title: 'VO2 Max',
                                        subtext: 'What\'s your VO2 Max today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'ml/kg/min',
                                            options: [
                                                { value: 'ml/kg/min', label: 'ml/kg/min' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Breathing Rate',
                                        is_selected: 0,
                                        img: 'respiration-rate.svg',
                                        title: 'Breathing Rate',
                                        subtext: 'What\'s your breathing rate today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'bpm',
                                            options: [
                                                { value: 'bpm', label: 'bpm' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Pulse Wave Velocity',
                                        is_selected: 0,
                                        img: 'heart-rate-variability.svg',
                                        title: 'Pulse Wave Velocity',
                                        subtext: 'What\'s your pulse wave velocity today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'pwv',
                                            options: [
                                                { value: 'pwv', label: 'pwv' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Recovery',
                                        is_selected: 0,
                                        img: 'recovery.svg',
                                        title: 'Recovery',
                                        subtext: 'What\'s your recovery scale',
                                        unit: {
                                            mode: 'single',
                                            value: '%',
                                            options: [
                                                { value: '%', label: 'Percentage' }
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    }
                                ]
                            },
                            {
                                group_title: 'Digestion',
                                items:[
                                    {
                                        name: 'Hunger',
                                        is_selected: 1,
                                        img: 'hunger.svg',
                                        title: 'Hunger',
                                        subtext: 'What\'s your level of hunger today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Fullness',
                                        is_selected: 1,
                                        img: 'fullness.svg',
                                        title: 'Fullness',
                                        subtext: 'What\'s your fullness level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Bloat',
                                        is_selected: 1,
                                        img: 'bloat.svg',
                                        has_show: 0,
                                        title: 'Bloat',
                                        subtext: 'What\'s your bloat level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },  
                                ]
                            },
                            {
                                group_title: 'Emotions',
                                items:[
                                    {
                                        name: 'Hunger',
                                        is_selected: 1,
                                        img: 'hunger.svg',
                                        title: 'Hunger',
                                        subtext: 'What\'s your level of hunger today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Fullness',
                                        is_selected: 1,
                                        img: 'fullness.svg',
                                        title: 'Fullness',
                                        subtext: 'What\'s your fullness level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Bloat',
                                        is_selected: 1,
                                        img: 'bloat.svg',
                                        has_show: 0,
                                        title: 'Bloat',
                                        subtext: 'What\'s your bloat level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },  
                                ]
                            },
                        ]
                    },
                    {
                        title: 'Your Best Results',
                        categories:[
                            {
                                group_title: 'Digestion',
                                items:[
                                    {
                                        name: 'Stress',
                                        is_selected: 0,
                                        img: 'stress.svg',
                                        title: 'Stress',
                                        subtext: 'What\'s your stress level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [5, 7, 6, 8, 7, 6, 5, 8, 9, 6, 5, 7, 9, 7, 8, 6, 7, 5, 8, 6, 9, 8, 7, 6, 8, 9, 7, 5, 7, 9],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 8 },
                                                            { yAxis: 6 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Mood',
                                        is_selected: 0,
                                        img: 'mood.svg',
                                        title: 'Mood',
                                        subtext: 'What\'s your mood today?',
                                        unit: {
                                            mode: 'single',
                                            value: '%',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Energy',
                                        is_selected: 0,
                                        img: 'energy.svg',
                                        has_show: 0,
                                        title: 'Energy',
                                        subtext: 'What\'s your energy level today?',
                                        unit: {
                                            mode: 'single',
                                            value: '%',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Libido',
                                        is_selected: 0,
                                        img: 'libido.svg',
                                        title: 'Libido',
                                        subtext: 'What\'s your libido level today?',
                                        unit: {
                                            mode: 'single',
                                            value: '%',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25, 45, 40, 65, 47, 40, 30, 25],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 55},
                                                        {name: 'Min', yAxis: 35},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                ]
                            },
                            {
                                group_title: 'Emotions',
                                items:[
                                    {
                                        name: 'Hunger',
                                        is_selected: 1,
                                        img: 'hunger.svg',
                                        title: 'Hunger',
                                        subtext: 'What\'s your level of hunger today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Fullness',
                                        is_selected: 1,
                                        img: 'fullness.svg',
                                        title: 'Fullness',
                                        subtext: 'What\'s your fullness level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Bloat',
                                        is_selected: 1,
                                        img: 'bloat.svg',
                                        has_show: 0,
                                        title: 'Bloat',
                                        subtext: 'What\'s your bloat level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },  
                                ]
                            },
                            {
                                group_title: 'Fitness',
                                items:[
                                    {
                                        name: 'Hunger',
                                        is_selected: 1,
                                        img: 'hunger.svg',
                                        title: 'Hunger',
                                        subtext: 'What\'s your level of hunger today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Fullness',
                                        is_selected: 1,
                                        img: 'fullness.svg',
                                        title: 'Fullness',
                                        subtext: 'What\'s your fullness level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Bloat',
                                        is_selected: 1,
                                        img: 'bloat.svg',
                                        has_show: 0,
                                        title: 'Bloat',
                                        subtext: 'What\'s your bloat level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },  
                                ]
                            },
                        ]
                    },
                    {
                        title: 'Your Pain Results',
                        categories:[
                            {
                                group_title: 'Pain',
                                items:[
                                    {
                                        name: 'Pain',
                                        is_selected: 0,
                                        img: 'pain.svg',
                                        title: 'Pain',
                                        subtext: 'In which area of your body do you feel pain?',
                                        isGoalRange: 1,
                                        positions:[
                                            ['Left', 'Superior', 'Lateral'],
                                            ['Right', 'Inferior', 'Lateral'],
                                            ['Right', 'Superior', 'Medial'],
                                        ],
                                        chart:{
                                            tooltip: {
                                                position: 'top',
                                                formatter: function(params) {
                                                    return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2]}/10`;
                                                },
                                                textStyle: {
                                                    fontSize: 11,
                                                    color: '#5a5a5a',
                                                },
                                            },
                                            animation: false,
                                            grid: {
                                                top: '2%',
                                                left: '2%',
                                                right: '2%',
                                                bottom: 80,
                                                containLabel: true,
                                            },
                                            xAxis: {
                                                type: 'category',
                                                data: ['Sep 24', 'Sep 25', 'Sep 26', 'Sep 27', 'Sep 28', 'Sep 29', 'Sep 30', 'Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9', 'Oct 10', 'Oct 11', 'Oct 12', 'Oct 13', 'Oct 14', 'Oct 15', 'Oct 16', 'Oct 17', 'Oct 18', 'Oct 19', 'Oct 20', 'Oct 21', 'Oct 22', 'Oct 23'],
                                                splitArea: {
                                                    show: false
                                                },
                                                axisTick: {
                                                    show: false,
                                                },
                                            },
                                            yAxis: {
                                                type: 'category',
                                                data: ['Shoulder', 'Back', 'Arm'],
                                                splitArea: {
                                                    show: false
                                                },
                                                axisTick: {
                                                    show: false,
                                                },
                                            },
                                            visualMap: {
                                                show: false,
                                                min: 0,
                                                max: 10,
                                                calculable: true,
                                                orient: 'horizontal',
                                                left: 'center',
                                                bottom: '15%',
                                                inRange: {
                                                    color: ['#2f7eed10', '#2f7eed',]
                                                }
                                            },
                                            series: [
                                                {
                                                    name: 'Shoulder',
                                                    type: 'heatmap',
                                                    data: [[0, 0, 8], [1, 0, 8], [2, 0, 8], [3, 0, 7], [4, 0, 7], [5, 0, 7], [6, 0, 6], [7, 0, 6], [8, 0, 6], [9, 0, 5], [10, 0, 5], [11, 0, 5], [12, 0, 4], [13, 0, 4], [14, 0, 4], [15, 0, 3], [16, 0, 3], [17, 0, 3], [18, 0, 2], [19, 0, 2], [20, 0, 2], [21, 0, 1], [22, 0, 1], [23, 0, 1], [24, 0, 0], [25, 0, 0], [26, 0, 0], [27, 0, 0], [28, 0, 0], [29, 0, 0]],
                                                    label: {
                                                        show: true,
                                                        color: '#5a5a5a',
                                                        formatter: function(value) {
                                                            return `${value.data[2]}`;
                                                        }
                                                    },
                                                    itemStyle: {
                                                        borderWidth: 3,
                                                        borderColor: '#fff'
                                                    },
                                                    emphasis: {
                                                        itemStyle: {
                                                            opacity: 1,
                                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                        }
                                                    }
                                                },
                                                {
                                                    name: 'Back',
                                                    type: 'heatmap',
                                                    data: [[0, 1, 7], [1, 1, 7], [2, 1, 7], [3, 1, 6], [4, 1, 6], [5, 1, 6], [6, 1, 5], [7, 1, 5], [8, 1, 5], [9, 1, 4], [10, 1, 4], [11, 1, 3], [12, 1, 3], [13, 1, 3], [14, 1, 2], [15, 1, 2], [16, 1, 2], [17, 1, 1], [18, 1, 0], [19, 1, 0], [20, 1, 0], [21, 1, 0], [22, 1, 0], [23, 1, 0], [24, 1, 0], [25, 1, 0], [26, 1, 0], [27, 1, 0], [28, 1, 0], [29, 1, 0]],
                                                    label: {
                                                        show: true,
                                                        color: '#5a5a5a',
                                                        formatter: function(value) {
                                                            return `${value.data[2]}`;
                                                        }
                                                    },
                                                    itemStyle: {
                                                        borderWidth: 3,
                                                        borderColor: '#fff'
                                                    },
                                                    emphasis: {
                                                        itemStyle: {
                                                            opacity: 1,
                                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                        }
                                                    }
                                                },
                                                {
                                                    name: 'Arm',
                                                    type: 'heatmap',
                                                    data: [[0, 2, 6], [1, 2, 6], [2, 2, 6], [3, 2, 5], [4, 2, 5], [5, 2, 5], [6, 2, 4], [7, 2, 4], [8, 2, 4], [9, 2, 3], [10, 2, 3], [11, 2, 3], [12, 2, 2], [13, 2, 2], [14, 2, 2], [15, 2, 1], [16, 2, 1], [17, 2, 1], [18, 2, 0], [19, 2, 0], [20, 2, 0], [21, 2, 0], [22, 2, 0], [23, 2, 0], [24, 2, 0], [25, 2, 0], [26, 2, 0], [27, 2, 0], [28, 2, 0], [29, 2, 0]],
                                                    label: {
                                                        show: true,
                                                        color: '#5a5a5a',
                                                        formatter: function(value) {
                                                            return `${value.data[2]}`;
                                                        }
                                                    },
                                                    itemStyle: {
                                                        borderWidth: 3,
                                                        borderColor: '#fff'
                                                    },
                                                    emphasis: {
                                                        itemStyle: {
                                                            opacity: 1,
                                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                        }
                                                    }
                                                },
                                            ],
                                            dataZoom: [{
                                                start: 0,
                                                end: 22,
                                                moveHandleSize: 10,
                                                moveHandleStyle: {
                                                    color: "#999",
                                                },
                                                height: 0,
                                                showDataShadow: false,
                                                showDetail: false,
                                                handleStyle: {
                                                    opacity: 0
                                                },
                                            }],
                                            media: [
                                                {
                                                    query: {
                                                        maxWidth: 991
                                                    },
                                                    option: {
                                                        dataZoom: [{
                                                            start: 0,
                                                            end: 22,
                                                        }],
                                                    }
                                                },
                                                {
                                                    query: {
                                                        maxWidth: 767
                                                    },
                                                    option: {
                                                        dataZoom: [{
                                                            start: 0,
                                                            end: 10,
                                                        }],
                                                    }
                                                },
                                                {
                                                    query: {
                                                        maxWidth: 499
                                                    },
                                                    option: {
                                                        dataZoom: [{
                                                            start: 0,
                                                            end: 7,
                                                        }],
                                                    }
                                                },
                                            ]
                                        }
                                    },
                                    {
                                        name: 'Inflammation',
                                        is_selected: 0,
                                        img: 'inflammation.svg',
                                        title: 'Inflammation',
                                        subtext: 'What\'s your inflammation level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 8 },
                                                            { yAxis: 6 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Mobility',
                                        is_selected: 0,
                                        img: 'mobility.svg',
                                        title: 'Mobility',
                                        subtext: 'What\'s your mobility level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 8 },
                                                            { yAxis: 6 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                ]
                            },
                            {
                                group_title: 'Digestion',
                                items:[
                                    {
                                        name: 'Hunger',
                                        is_selected: 1,
                                        img: 'hunger.svg',
                                        title: 'Hunger',
                                        subtext: 'What\'s your level of hunger today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Fullness',
                                        is_selected: 1,
                                        img: 'fullness.svg',
                                        title: 'Fullness',
                                        subtext: 'What\'s your fullness level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Bloat',
                                        is_selected: 1,
                                        img: 'bloat.svg',
                                        has_show: 0,
                                        title: 'Bloat',
                                        subtext: 'What\'s your bloat level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },  
                                ]
                            },
                            {
                                group_title: 'Emotions',
                                items:[
                                    {
                                        name: 'Hunger',
                                        is_selected: 1,
                                        img: 'hunger.svg',
                                        title: 'Hunger',
                                        subtext: 'What\'s your level of hunger today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Fullness',
                                        is_selected: 1,
                                        img: 'fullness.svg',
                                        title: 'Fullness',
                                        subtext: 'What\'s your fullness level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    {
                                        name: 'Bloat',
                                        is_selected: 1,
                                        img: 'bloat.svg',
                                        has_show: 0,
                                        title: 'Bloat',
                                        subtext: 'What\'s your bloat level today?',
                                        unit: {
                                            mode: 'single',
                                            value: 'Ratings',
                                            options: [
                                                { value: 'Ratings', label: 'Ratings' },
                                                { value: 'Number', label: 'Number' },
                                                { value: '%', label: 'Percentage' },
                                            ],
                                        },
                                        max_val: 10,
                                        isGoalRange: 1,
                                        chart: [
                                            {
                                                name: '',
                                                type: 'line',
                                                smooth: true,
                                                symbol: 'circle',
                                                symbolSize: 10,
                                                lineStyle: {
                                                    color: {
                                                        type: 'linear',
                                                        x: 0,
                                                        y: 0,
                                                        x2: 0,
                                                        y2: 1,
                                                        colorStops: [
                                                            {
                                                                offset: 0,
                                                                color: '#2F7FED'
                                                            },
                                                            {
                                                                offset: 1,
                                                                color: '#88CBEE'
                                                            }
                                                        ],
                                                        global: false
                                                    }
                                                },
                                                data: [8, 7, 9, 7, 7, 8, 9, 7, 8, 8, 9, 8, 9, 8, 7, 7, 8, 7, 7, 8, 8, 7, 9, 8, 8, 7, 8, 8, 9, 8],
                                                markPoint: {
                                                    symbol: 'circle',
                                                    symbolSize: 20,
                                                    label: {
                                                        fontSize: 10,
                                                        color: '#fff'
                                                    },
                                                    data: [],
                                                },
                                                markLine: {
                                                    data: [
                                                        {name: 'Max', yAxis: 8},
                                                        {name: 'Min', yAxis: 6},
                                                    ],
                                                    label:{
                                                        position: 'end',
                                                    },
                                                    lineStyle:{
                                                        opacity: 1,
                                                        width: 0,
                                                    },
                                                    symbol: 'none',
                                                },
                                                markArea: {
                                                    data: [
                                                        [
                                                            { yAxis: 55 },
                                                            { yAxis: 35 }
                                                        ],
                                                    ],
                                                    itemStyle: {
                                                        color: 'rgba(47,127,237,0.08)',
                                                        opacity: 1,
                                                    },
                                                }
                                            }
                                        ],
                                    },  
                                ]
                            },
                        ]
                    }
                ],
                selectScenario: 0,
                allAttr: 1,
                selectedProgress: [],
            }
        },
        components:{
            ImageUpload,
            WorkoutsStatsDetails,
            HabitsStatsDetails,
            ProgressStatsDetails,
            GoalsStatsDetails,
            // VueEcharts
        },
        computed: {
            ...mapState({
                user: state => state.authModule.user,
            }),
        },
        mounted(){
            const vm = this;
            vm.handleProgressFilterSelection();
        },
        watch: {
            'progressFilter.tracking' : {
                handler (val) {
                    const vm = this;

                    if(val){
                        vm.handleProgressFilterSelection();
                    }
                },
                deep: true,
            }
        },

        methods: {
            toggleTab (tab) {
                const vm = this;

                vm.tab = tab;
            },

            scrollFunc (e) {
                e.preventDefault();
                e.currentTarget.scrollLeft += e.deltaY;
            },

            handleWeekDialog (wk) {
                const vm = this;

                vm.weekSr = wk;
                vm.WeekDialog = true;
            },
            closeTimelineDropdown(){
                const vm = this;
                vm.timeline = false;
            },
            toggleFunc(ev){
                let el = ev.currentTarget.closest('.content_card_wpr');
                const subEl = el.querySelectorAll('.list li');
                
                if (el.classList.contains('show')){
                    el.classList.remove('show');
                    for(let i = 0; i < subEl.length; i++){
                        subEl[i].classList.remove('show');
                    }
                } else {
                    el.classList.add('show');
                }
            },
            showDetails(ev){
                const el = ev.currentTarget.closest('li');
                if(el.classList.contains('show')){
                    el.classList.remove('show');
                }else{
                    el.classList.add('show');
                }
            },
            accFunc(ev){
                const vm = this;

                let el = ev.currentTarget.closest('.category_grp');
                if(el.classList.contains('active')){
                    el.classList.remove('active');
                    vm.allAttr = 1;
                }else{
                    el.classList.add('active');
                    vm.allAttr = 0;
                }
            },
            openAllGrp () {
                const vm = this;

                let allParentElm = document.querySelectorAll('.category_grp');

                for(let i = 0; i < allParentElm.length; i++){
                    allParentElm[i].classList.add('active');
                }

                vm.allAttr = 0;
            },

            closeAllGrp () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.category_grp');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('active');
                }

                vm.allAttr = 1;
            },
            closeOpenFilter(){
                const vm = this;
                vm.openFilter = false;
            },
            closeScenarioDropdown(){
                const vm = this;
                vm.scenarioDropdown = false;
            },
            closeFilterBar(){
                const vm = this;
                vm.openFilter = false;
            },
            handleProgressFilterSelection(){
                const vm = this;
                let x = vm.ProgressScenarioList.findIndex((att) => att.title === vm.progressFilter.tracking);
                vm.selectedProgress = vm.ProgressScenarioList[x].categories;
            }
            // handleSelection(el, cat){
            //     let hasEl = cat.findIndex((att) => att === el);

            //     if(hasEl == -1){
            //         if(cat.length < 5){
            //             cat.push(el);
            //         }
            //     } else{
            //         cat.splice(hasEl, 1);
            //     }
            // }
        }
    }
</script>

<style scoped>
    .portal_forms{
        margin-bottom: 50px;
    }
    .inner_pages .actions .filter_btn {
        padding: 10px 0;
        display: flex;
        gap: 30px;
    }
    .inner_pages .actions .fitbit{
        padding: 0;
    }

    .inner_pages .actions .filter_btn .btn_item {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        position: relative;
        transition: all 0.3s ease-in-out;
    }

    .inner_pages .actions .filter_btn .btn_item.active {
        color: #121525;
        font-weight: 500;
    }

    .empty_section {
        border: 1px solid #D9D9D9;
        border-radius: 10px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;
    }

    .empty_section h1 {
        font-size: 25px;
        line-height: 32px;
        font-weight: 300;
        color: #757575;
        margin-bottom: 30px;
    }

    .empty_section button {
        width: 180px;
        height: 50px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 15px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #fff;
        background: #2f7eed;
    }
    .habit_calendar {
        display: flex;
        flex-wrap: wrap;
        max-width: 900px;
        margin: 0 auto 40px auto;
    }
    .vc-container {
        min-width: 80%;
        padding: 0 20px;
        background: transparent;
    }
    :deep(.habit_calendar .vc-container .vc-day-content){
        background: #efefef;
    }
    :deep(.habit_calendar .vc-container .vc-day .assign_day){
        background: #939393;
    }
    :deep(.habit_calendar .vc-container .vc-day .vc-highlight){
        background-color: #939393 !important;
    }
    .habit_calendar .streaks {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 30px 0 10px 0;
        width: 20%;
    }
    .habit_calendar .streaks h4 {
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        margin: 0 0 10px;
    }
    .habit_calendar .streaks ul {
        flex: 0 1 335px;
        margin: 52px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 10px 30px;
        border-left: 1px solid #e5e5e5;
    }
    .habit_calendar .streaks ul:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 10px;
        bottom: 10px;
        border-left: 1px solid #d9d9d9;
        z-index: 0;
    }
    .habit_calendar .streaks li {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #fff;
        /* color: var(--var-element-bg-color); */
        color: #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #d9d9d9;
        font-size: 14px;
        position: relative;
    }
    .habit_calendar .streaks li i {
        filter: grayscale(1);
        opacity: 0.5;
    }
    .habit_calendar .streaks li.winner {
        border: 1px solid #2f7eed;
    }
    .habit_calendar .streaks li.winner i {
        filter: grayscale(0);
        opacity: 1;
    }
    .task_details .category_wpr h3.sub_header {
        margin: 0 0 10px;
        font-size: 13px !important;
        line-height: 17px !important;
        font-weight: 500;
        color: #121525;
    }
    .task_details .perform_activity{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
    .task_details .task_info{
        margin-bottom: 25px;
    }
    .task_details .task_info h4{
        /* color: var(--var-element-text-color); */
        color: #121525;
    }
    .task_details .task_header .stat{
        /* color: var(--var-element-bg-color); */
        color: #121525;
    }
    .task_details .task_info h4 label {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        /* border: 1px solid var(--var-element-bg-color);
        color: var(--var-element-bg-color); */
        border: 1px solid #5a5a5a;
        color: #5a5a5a;
        font-size: 9px;
        line-height: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin: 2px 12px 0 0;
    }
    .task_details .task_info h4 i{
        /* color: var(--var-element-bg-color); */
        color: #5a5a5a;
    }
    .task_details .task_info .details{
        padding-bottom: 10px;
    }
    .task_details .task_info .details p{
        padding: 0 0 5px 30px;
    }
    .task_details .success_goal {
        display: flex;
        align-items: center;
        border-radius: 20px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        width: 100%;
        padding: 10px 0 25px 30px;
    }
    .task_details .success_goal i{
        /* color: var(--var-element-bg-color); */
        color: #5a5a5a;
        font-size: 12px;
        padding-right:10px;
    }
    .task_details .achieved_goal{
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        border: 1px solid #d4d4d4;
        background: #fbfbfb;
        color: #121525;
        max-width: 150px;
        margin: 10px auto 20px auto;
        padding: 10px 20px;
        border-radius: 20px;
        text-align: center;
        display: block;
    }
    .task_details .achieved_goal i{
        /* color: var(--var-element-bg-color); */
        color: #5a5a5a;
        padding-right: 5px;
    }
    .days_wpr{
        border-top: 1px dashed #E4E7EC;
        padding: 20px 0 0;
        width: 100%;
        text-align: center;
    }
    .task_details ul.days{
        display: flex;
        justify-content: center;
        padding: 7px 0 20px 0;
        gap: 4px;
    }
    .task_details .days li label .item_wpr{
        width: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 8px 6px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
    }

    .task_details .days li label span{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 0;
    }
    .task_details .days li label .tick_box{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #cdcdcd;
        position: relative;
        margin-top: 7px;
    }
    .task_details .days li label .tick_box:after{
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        /* background-color: var(--var-element-bg-color); */
        color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .task_details .days li label input[type=checkbox]:checked ~ .item_wpr{
        background: #fbfbfb;
        border: 1px solid #d4d4d4;
    }
    .task_details .days li label input[type=checkbox]:checked ~ .item_wpr .tick_box:after{
        transform: scale(1);
    }

    .activity_wpr{
        padding: 5px 0 0 30px;
        width: 100%;
    }
    .activity_wpr_center{
        padding: 5px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .grp_wpr {
        display: flex;
        gap: 8px;
        padding: 10px 0 25px 0;
    }
    .grp_wpr label .box_item{
        font-size: 13px;
        line-height: 15px;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }
    .grp_wpr label .box_item i{
        font-size: 15px;
    }
    .grp_wpr label.yes .box_item {
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
    }
    .grp_wpr label.no .box_item {
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
    }
    .grp_wpr label.active .box_item, .grp_wpr label input[type="radio"]:checked ~ .box_item {
        opacity: 1;
    }
    .task_details .quest_panel{
        padding: 25px 15px 0 15px;
        background: #fafafb;
    }
    .upload_image :deep(.upload_wpr .upload_area){
        width: 100%;
        min-height: 140px;
        padding: 0;
        border-color: #d4d4d4;
        background-color: #fbfbfb;
    }
    .upload_image :deep(.upload_wpr .upload_area img:not(.uploaded_img)){
        max-width: 50px;
    }
    .upload_image :deep(.upload_wpr .upload_area h4){
        font-size: 11px;
        line-height: 15px;
        padding: 0;
    }
    .upload_image :deep(.tab_content){
        height: 100%;
    }
    .tracking_list li h4 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #32373b;
    }
    .tracking_list li h4 span {
        font-size: 18px;
        line-height: 23px;
        display: block;
        color: #121525;
        padding-top: 8px;
    }
    .report_card {
        border: 1px solid #eaeaea;
        border-radius: 6px;
        margin-bottom: 40px;
        background: #fff;
    }

    .report_card .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
    }

    .report_card .user_info {
        display: flex;
        text-align: left;
        align-items: center;
    }

    .report_card .user_info img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-right: 15px;
        border: 1px solid #ccc;
    }

    .report_card .header h4 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #32373b;
        margin-bottom: 5px;
    }

    .report_card .header h3 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 300;
        color: #32373b;
        margin-bottom: 0;
    }
    .habit_wpr{
        border: 1px solid #eaeaea;
        border-radius: 6px;
        text-align: left;
    }
.habit_wpr .title{
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: #121525;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    border-radius: 6px 6px 0 0;
    justify-content: space-between;
}
.habit_wpr .title .right{
    display: flex;
    align-items: center;
    color: #9b9b9b;
}
.habit_wpr .title .right button{
    position: relative;
}
.habit_wpr .quick_info{
    border-radius: 5px;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    background: #fff;
    width: 200px;
    text-align: left;
    position: absolute;
    right: 100%;
    top: -5px;
}
.habit_wpr .quick_info .info_title{
    font-size: 11px;
    line-height: 13px;
    color: #121525;
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    padding: 5px 10px;
}
.habit_wpr .quick_info p{
    font-size: 9px;
    line-height: 11px;
    color: #5a5a5a;
    font-weight: 500;
    padding: 7px 10px;
}
.habit_wpr .habit_info{
    padding: 20px;
    background: #fff;
}
.habit_wpr .habit_info ul{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}
.habit_wpr .habit_info ul li{
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: auto;
}
.habit_wpr .habit_info h6{
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    margin-left: 10px;
}
.habit_wpr .habit_info .ratios{
    font-size: 10px;
    color: #919191;
}
.details_dialog .modal_container{
    padding: 0;
    display: block;
    border-radius: 6px;
}
.details_dialog .modal_header{
    padding: 15px 30px;
    margin: 0;
    flex-direction: row;
}
.details_dialog .modal_header .close_btn {
    right: -5px;
    top: -5px;
    background: #888;
}
.details_dialog .modal_body{
    padding: 20px 30px;
    max-height: 500px;
}
.category_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    position: relative;
}
.category_title h2{
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    color: #121525;
}
.section_header button, .category_title button {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    padding: 7px 15px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background: #f5f5f5;
    color: #2c3e50;
}
.result_wpr.new{
    overflow: inherit;
    /* margin-top: 12px; */
}
.prog_wpr{
    margin-bottom: 40px;
}
.prog_wpr table{
    min-width: 500px;
}
.result_wpr table td:first-child, .result_wpr table th:first-child {
    width: auto;
    padding: 20px;
    text-align: left;
}
.result_wpr table th{
    background: #d4d4d4;
}
.result_wpr table th:first-child{
    border-top-left-radius: 10px;
}
.result_wpr table th:last-child{
    border-top-right-radius: 10px;
}
.result_wpr table td h6 {
    font-size: 11px;
    line-height: 15px;
    color: #2f7eed;
    font-weight: 500;
    text-align: right;
}
.result_wpr table td .status{
    height: 4px;
    border-radius: 2px;
    background: #eaeaea;
    position: relative;
}
.score_circle .inner_circle{
    background: #fff;
}
.result_wpr.new .actions{
    margin-bottom: 25px;
}
h3.category_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #121525;
    margin: 0 0 15px;
}
.stat_list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.stat_list li{
    width: 25%;
    padding: 0 10px 20px 10px;
}
.stat_list .stat_card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    gap: 10px;
    border: 1px solid #f5f5f5;
}
.stat_list .stat_card .title h5{
    font-size: 11px;
    line-height: 15px;
    color: #5a5a5a;
    font-weight: 400;
    margin-bottom: 10px;
}
.stat_list .stat_card .title h3{
    font-size: 16px;
    line-height: 20px;
    color: #121525;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}
.stat_list .stat_card .title h3 i{
    font-size: 13px;
    color: #2f7eed;
}
.stat_list .stat_card .title h3 .field_wpr{
    width: 85px;
}
.stat_list .stat_card .title h3 .field_wpr .multiselect{
    height: 20px;
    min-height: 20px;
    border: 0;
}
.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-caret){
    margin-right: 5px;
    height: 11px;
}
.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-clear){
    display: none;
}
.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-multiple-label), 
.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-placeholder), 
.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-single-label){
    padding: 0 5px;
    font-size: 9px;
    line-height: 10px;
}
.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-dropdown ul.multiselect-options li){
    font-size: 9px;
    line-height: 10px;
    padding: 5px;
}
.overview .rate{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.overview .rate .avr_score{
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #2f7eed;
    padding: 2px 7px;
    border: 1px solid #2f7eed;
    border-radius: 10px;
}
.overview .rate h6{
    font-size: 9px;
    line-height: 10px;
    color: #5a5a5a;
    font-weight: 500;
    margin-top: 5px;
    max-width: 100px;
    text-align:center;
    /* display: none; */
}
.overview .score_circle .inner_circle .score {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
}
.overview .score_circle .inner_circle .score span{
    font-size: 8px;
}
.fitbit_connector{
    display: flex;
    justify-content: flex-end;
    /* margin-bottom: 20px; */
}
.fitbit_connector .capsule_btn div {
  flex: 0 0 30px;
  width: 30px;
  height: 16px;
}
.fitbit_connector .capsule_btn div span {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #F2A31D;
  transition: all 0.3s ease-in-out;
  margin: 0;
}
.fitbit_connector .switch_option h5 {
    font-size: 13px;
    line-height: 16px;
}
.widgets_wpr span.chart_title{
    left: 0;
}

/* .overview{
    display: flex;
    gap: 40px;
} */
.overview .quick_status{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 30px;
}
.overview .quick_status .quick_card{
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0,0,0,0.03);
    width: calc(33.333% - 20px);
    display: flex;
    flex-direction: column;
}
.overview .quick_status .quick_card .title{
    font-size: 16px;
    line-height: 23px;
    color: #121525;
    font-weight: 500;
    padding: 15px 20px;
    row-gap: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.overview .quick_status .quick_card .title svg{
    width: 18px;
    height: 18px;
    margin-right: 10px;
}
.overview .quick_status .quick_card .title ul{
    margin-left: auto;
}
.overview .quick_status .quick_card .title ul li{
    height: 32px;
    width: auto;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    position: relative;
}
.overview .quick_status .quick_card .title ul li select{
    border: 0;
    background: transparent;
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #5a5a5a;
    padding: 0 30px 0 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}
.overview .quick_status .quick_card .title ul li > span{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #e9e9e9;
    font-size: 13px;
    color: #767676;
    pointer-events: none;
}
.overview .quick_status .quick_card .rankboard{
    overflow-y: scroll;
    height: 400px;
}
.overview .quick_status .quick_card .rankboard::-webkit-scrollbar{
    width: 3px;
}
.overview .quick_status .quick_card .rankboard::-webkit-scrollbar-thumb{
    background: #dbdbdb;
    border-radius: 2px;
}
.overview .quick_status .quick_card .rankboard li {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
}
.overview .quick_status .quick_card .rankboard li .board_pos {
    width: 18px;
    height: 18px;
    background: #e9e9e9;
    color: #121525;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    margin-right: 10px;
}
.overview .quick_status .quick_card .rankboard li:nth-child(-n + 3) .board_pos{
    background: #84b7ff;
    color: #fff;
    position: relative;
    margin-bottom: 3px;
}
.overview .quick_status .quick_card .rankboard li:nth-child(-n + 3) .board_pos:after{
    position: absolute;
    content: '';
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('~@/assets/images/badge_tails.svg');
    background-repeat: no-repeat;
    width: 10px;
    height: 6px;
    background-size: cover;
    background-position: 50% 100%;
}
.overview .quick_status .quick_card .rankboard li img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin-right: 10px;
}
.overview .quick_status .quick_card .rankboard li h5 {
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
}
.overview .quick_status .quick_card .rankboard li .private{
    width: 50%;
    height: 18px;
    background: #efefef;
    border-radius: 12px;
}
.overview .quick_status .quick_card .rankboard li .score_circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 2px;
    background-color: #f4f4f4;
    position: relative;
    margin-left: auto;
}
.overview .quick_status .quick_card .rankboard li.focus{
    position: relative;
    z-index: 1;
    /* position: sticky;
    top: 0;
    bottom: 0; */
}
.overview .quick_status .quick_card .card_info{
    padding: 10px 20px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}
/* .overview .quick_status .quick_card .card_info .goal_name{
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #5a5a5a;
    padding-bottom: 25px;
} */
.quick_card .card_info ul{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 10px;
}
.quick_card .card_info ul li{
    display: flex;
    gap: 15px;
    font-size: 12px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 400;
    align-items: center;
    padding-bottom: 8px;
}
.quick_card .card_info ul li .tag{
    /* background: #ecf4ff; */
    padding: 0 6px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 18px;
    color: #2f7eed;
    font-weight: 400;
    margin-left: auto;
}
.quick_card .card_info ul li .item_details{
    font-size: 12px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.quick_card .card_info ul li .item_details:nth-of-type(1){
    width: calc(45% - 10px);
}
.quick_card .card_info ul li .item_details:nth-of-type(2){
    width: calc(30% - 10px);
}
.quick_card .card_info ul li .item_details:nth-of-type(3){
    width: calc(25% - 10px);
}
.quick_card .card_info ul li .item_details label{
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #5a5a5a;
}
.quick_card .card_info ul li .rate{
    width: 40px;
    flex-shrink: 0;
    margin-left: auto;
}
.quick_card .card_info .actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    margin-top: auto;
}
.quick_card .card_info .actions a{
    font-size: 13px;
    line-height: 16px;
    padding: 5px 0;
    color: #2f7eed;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: flex-end;
    gap: 7px;
}
.quick_card .card_info .actions a i{
    font-size: 13px;
}
.quick_card .card_info .actions button{
    font-size: 11px;
    line-height: 14px;
    padding: 5px 12px;
    border-radius: 14px;
    border: 1px solid #b4d3ff;
    color: #2f7eed;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
}
.quick_card .card_info .plan_name{
    font-size: 15px;
    line-height: 22px;
    color: #5a5a5a;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 25px;
}
.quick_card .picture_section{
    display: flex;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
}
.quick_card .picture_section li{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.quick_card .picture_section li:not(:first-child){
    border-left: 1px solid #f5f5f5;
}
.quick_card .picture_section li .section_title{
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    padding: 7px 15px;
    display:  none;
}
.quick_card .picture_section li .img_viewer{
    flex: 1 1 auto;
}
.quick_card .picture_section li .img_viewer img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.overview .left_panel{
    flex: 1 1 auto;
}
.overview .right_panel{
    width: 400px;
    flex-shrink: 0;
}
.overview .widgets_wpr {
    padding: 20px 30px;
    border-radius: 8px;
    height: auto;
    margin-bottom: 30px;
}
.overview .widgets_wpr .header h2 {
    font-size: 22px;
    line-height: 30px;
}
.overview .widgets_wpr .header .lb_tabs{
    display: flex;
    gap: 5px;
    margin: auto 0;
}
.overview .widgets_wpr .header .lb_tabs button{
    background: transparent;
    height: 27px;
    padding: 5px 10px;
    color: #121525;
    font-size: 11px;
    line-height: 1;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    outline: 0;
    text-transform: capitalize;
}
.overview .widgets_wpr .header .lb_tabs button.active{
    background: #dbdbdb;
}
.overview .right_panel .widgets_wpr span.chart_title{
    font-size: 11px;
    letter-spacing: 0.7px;
}
.overview .right_panel .widgets_wpr ul li {
    width: 125px;
    height: 28px;
}
.overview .right_panel .widgets_wpr ul li select{
    font-size: 11px;
    padding: 0 25px 0 10px;
}
.overview .right_panel .stat_list{
    margin: 0;
    padding: 10px 30px;
    background: #fff;
    border-radius: 8px;
}
.overview .right_panel .stat_list li{
    width: 100%;
    padding: 0;
}
.overview .right_panel .stat_list li:not(:first-child){
    border-top: 1px solid #f5f5f5;
}
.overview .right_panel .stat_list li .stat_card{
    border: 0;
    border-radius: 0;
    padding: 20px 0;
}
.overview .right_panel .stat_list .stat_card .rate{
    width: 60px;
}
.result_wpr.new .actions > ul li.optionDrops img{
    height: 16px;
}
.result_wpr.new .actions > ul li.search_area{
    background: #ececec;
    /* height: 31px; */
}

.result_wpr.new .actions > ul li.search_area.active input[type=text]{
    width: 200px;
}
@media(max-width: 1350px){
    .stat_list li{
        width: 33.333%;
    }
}
@media(max-width: 1199px){
    .overview .quick_status .quick_card{
        width: calc(50% - 15px);
    }
}
@media(min-width:992px) and (max-width: 1099px){
    .stat_list li{
        width: 50%;
    }
}
@media(max-width: 850px){
    .stat_list li{
        width: 50%;
    }
}
@media(max-width: 767px){
    .inner_pages .actions.filter_bar .filter_btn .btn_item{
        font-size: 13px;
        padding: 0 20px;
    }
    .empty_section h1{
        font-size: 16px;
        line-height: 23px;
    }
    .empty_section button{
        height: 40px;
        font-size: 13px;
    }
    .report_card .header{
        padding: 20px;
    }
    .portal_forms .report_card .user_info img {
        height: 35px ;
        width: 35px ;
    }
    .portal_forms .report_card .header h4{
        font-size: 11px;
    }
    .portal_forms .report_card .header h3 {
        font-size: 13px !important;
        line-height: 17px !important;
        display: block;
    }
    .prog_wpr table th, .prog_wpr table td{
        font-size: 11px;
        line-height: 15px;
        padding: 15px 10px !important;
    }
    .prog_wpr table td:not(:last-child){
        width: 22%;
    }
    .prog_wpr table .score_circle{
        transform: scale(0.8);
    }
}
@media(max-width: 599px){
    .overview .quick_status .quick_card{
        width: 100%;
    }
    .result_wpr.new .actions > ul li.search_area.active input[type=text]{
        width: 30vw;
    }
}
@media(max-width: 499px){
    .inner_pages .actions.filter_bar .filter_btn .btn_item{
        font-size: 11px;
        line-height: 14px;
        padding: 0 10px;
    }
    .report_card{
        margin-bottom: 40px;
    }
    .inner_pages .actions .filter_btn{
        gap: 20px;
        padding-left: 5px;
    }
    .inner_pages .actions .filter_btn .btn_item{
        font-size: 13px;
        line-height: 18px;
    }
    .portal_forms .report_card .header h3 span {
        display: block;
        font-size: 10px;
        line-height: 15px;
    }
    .stat_list li{
        width: 100%;
    }
    .overview .score_circle{
        transform: scale(1);
    }
}
@media(max-width: 450px){
    .result_wpr.new{
        margin: 0 0 20px 0;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        width: 100%;
        justify-content: flex-start;
    }
    .result_wpr.new .actions > ul li.search_area{
        width: 100%;
        order: -1;
    }
    .result_wpr.new .actions > ul li.search_area input[type=text]{
        padding: 0 0 0 15px;
    }
    .result_wpr.new .actions > ul > li:last-child{
        margin-left: auto;
    }
}
</style>